@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?h3htvj');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?h3htvj#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?h3htvj') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?h3htvj') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?h3htvj##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-wicons_woutickPlus {
  &:before {
    content: $icon-wicons_woutickPlus; 
  }
}

