//  FONT
$textFontFamily: "Poppins", sans-serif;

//  COLORS
/* GENERAL */
* {
  --primaryColor: white;
  --secondaryColor: #cd3467;
  --emphasis: #cd3467;
  --interactive: #cd3467;
  --selectedText: #1d1d1b;
  --tick: #cd3467;
  --pro: #6cc4c7;
  --pass: #33cc33;
  --pal: #ff6633;
  --hub: #f9b100;
  --feder: #2a36e5;
  --woutickplus: #cc3366;
}

$backgroundColor: #eeeeee;
$fontColor: #1d1d1b;
$borderColor: rgba(0, 0, 0, 0.3);

$primaryColor: var(--primaryColor);
$primaryTitle: #1d1d1b;
$primaryIcon: #1d1d1b;

$secondaryColor: var(--secondaryColor);
$secondaryTitle: black;
$secondaryIcon: #cd3467;

$emphasis: var(--emphasis);
$interactive: var(--interactive);
$selectedText: var(--selectedText);

$alert: red;

/*  WOUTICK */
$tick: var(--tick);
$pro: var(--pro);
$pass: var(--pass);
$pal: var(--pal);
$hub: var(--hub);
$feder: var(--feder);
$woutickplus: var(--woutickplus);

$cancel: #cc3333;
$accept: #33cc33;
$progress: #6cc4c7;
$timeless: #009999;
$warning: #f9b100;

/* CARDS */
$backgroundCards: white;
$fontCards: black;

/* TABS */
$backgroundTabs: white;
$fontTabs: black;

/* INPUTS */
$backgroundInput: white;
$fontInput: black;
$borderInput: black;
$placeholderInput: rgba($fontInput, 0.7);

/* BUTTONS */
$backgroundButton: white;
$fontButton: black;
$borderButton: $interactive;
$hoverButton: white;

@mixin backgroundenfasis($percentage) {
  background-color: rgba($emphasis, $percentage);
}
