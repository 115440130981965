@import "partials/variables";
@import "partials/mixins";
@import "partials/typography";
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,900");

button:focus,
input:focus {
  outline: none;
}

*:not(i) {
  font-family: $textFontFamily !important;
  $font-family-base: "Poppins" !important;
  $font-family-ios-base: "Poppins" !important;
  $font-family-md-base: "Poppins" !important;
  $font-family-wp-base: "Poppins" !important;
}

:root {
  --ion-font-family: "Poppins" !important;
}

ion-header.transparent {
  background: transparent;
  backdrop-filter: blur(0px);

  ion-toolbar {
    --background: transparent;

    ion-title.title {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      text-align: left;
      @include principal6(white);
      padding: 0 90px 0 60px;
    }

    ion-buttons {
      &.mobile {
        display: none;
      }

      ion-menu-button,
      ion-back-button {
        color: white;
      }

      ion-button {
        .icon {
          color: white;
          font-size: 27px;
        }
      }
    }
  }
}

.footer-md::before {
  background-image: none !important;
}

ion-footer.transparent {
  background: transparent;
  backdrop-filter: blur(0px);

  &.dark {
    background: black;
  }

  ion-toolbar {
    --background: transparent;
  }
}

ion-content.transparent {
  --background: rgba(0, 0, 0, 0.2);
}

ion-header.menu {
  background-color: $primaryColor;

  ion-toolbar {
    --background: var(--primaryColor);
    --padding-bottom: 0;
    --padding-top: 0;

    ion-buttons {
      &.mobile {
        display: none;
      }

      ion-menu-button,
      ion-back-button {
        color: $primaryIcon;
      }

      ion-button {
        // &.search-button {
        //   display: none;
        // }

        .icon {
          color: $primaryIcon;
          font-size: 27px;

          &.icon-cancel-circle {
            color: $cancel !important;
          }
        }
      }
    }

    ion-title.title {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      text-align: left;
      @include principal6($primaryTitle);
      padding: 0 60px 0 60px;
      padding-inline-start: 60px !important;
    }
  }

  &.secondary {
    background-color: $secondaryColor;

    ion-toolbar {
      --background: var(--secondaryColor);

      ion-buttons {
        ion-menu-button,
        ion-back-button {
          color: $secondaryIcon;
        }

        ion-button {
          .icon {
            color: $secondaryIcon;
          }
        }
      }

      ion-title {
        @include principal6($secondaryTitle);
      }
    }
  }
}

ion-header.submenu {
  background-color: $primaryColor;
  z-index: 1;

  ion-toolbar {
    --background: var(--primaryColor);
    display: flex;
    align-items: center;
    height: 40px;

    ion-buttons {
      &.marg-left {
        margin-left: 16px;
      }

      &.marg-right {
        margin-right: 16px;
      }

      ion-button {
        margin: 0;
        --border-radius: 25px !important;
        --border-color: var(--interactive);
        --border-width: 1px;
        --border-style: solid;

        &.icon-button {
          width: 28px;
          height: 28px;

          .icon {
            color: $interactive;
            font-size: 28px;
          }
        }

        &.title-button {
          width: auto;
          height: 28px;

          ion-title {
            padding: 0 8px;
            position: relative;
            font-size: 14px;
            color: $fontButton;
            align-items: center;
            text-transform: uppercase;
          }
        }
      }
    }

    ion-title {
      position: relative;
      padding: 0 8px;
      height: 40px;
      display: flex;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      align-items: center;

      .bold {
        font-weight: 900;
        font-size: 16px;
      }
    }
  }
}

ion-footer.menu {
  background-color: $primaryColor;

  &.secondary {
    background-color: $secondaryColor;
  }

  ion-toolbar {
    --background: transparent;
  }
}

.ion-page {
  background: transparent;
}

ion-content {
  --ion-background-color: #eeeeee;
  color: $fontColor;
}

.loading,
.no-content {
  text-align: center;
  position: relative;
  padding: 0;
  display: grid;
  height: 100px;
  @include subtitulo1(rgba($fontColor, 0.5));
}

ion-toolbar.searchbar-container {
  position: relative;
  top: 0;
  width: 100%;
  z-index: 0;
  transition: top 0.5s ease 0s;
  --background: transparent;
  --border-width: 0;

  &.hide {
    height: 0;
    width: 0;
    top: -100px;
    left: 100px;
  }

  ion-searchbar {
    padding: 12px 8px;
    --background: white;
  }
}

ion-list.card {
  background-color: white;
  border-radius: 15px;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px,
    rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
  width: 95%;
  margin: auto;
}

button.fab-button {
  position: absolute;
  z-index: 10;
  bottom: 0;
  right: 0;
  margin: 35px;
  padding: 12px;
  border-radius: 25px;
  transition: all linear 0.5s;

  &.search {
    background-color: $interactive !important;
  }

  &.cancel {
    background-color: $cancel !important;

    .mat-button-wrapper {
      padding: 0;

      .icon {
        font-size: 37px;
      }
    }
  }

  &.cart {
    position: fixed;
    background-color: $emphasis !important;
  }

  .icon {
    color: $primaryIcon;
    font-size: 27px;
  }
}

.order-popover {
  --backdrop-opacity: 0.4;
  // --height: 280px;
  --width: 400px;

  &.sc-ion-popover-ios-h {
    .popover-content {
      left: 0 !important;
      width: 100% !important;
    }
  }

  .popover-content {
    height: 95% !important;
    top: auto !important;
    bottom: 0 !important;
    position: absolute !important;
    background: white !important;
    overflow: hidden !important;
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;

    order-pro {
      height: 100% !important;
    }
  }

  .buttons-container {
    display: flex;
    justify-content: space-around;
    bottom: 0;
    padding: 8px 0;
    width: 100%;
    background-color: white;
  }
}

.default-popover {
  --backdrop-opacity: 0.4;
  // --height: 280px;
  --width: 400px;

  &.sc-ion-popover-ios-h {
    --width: 360px !important;
  }

  .popover-content {
    top: 20% !important;
    position: static !important;
    background: white !important;
    overflow: hidden !important;
    border-radius: 20px !important;
  }

  ion-header {
    height: 40px;
    background: #1d1d1b;
    display: flex;

    ion-title {
      @include principal5(white);
      font-weight: 900;
      margin: auto;
      text-align: center;
    }
  }

  .container {
    display: grid;
    grid-template-columns: 100%;
    margin: auto;
    height: 100%;

    .info-container {
      margin: 16px;

      .products {
        width: 100%;
        display: grid;
        grid-template-columns: auto auto;
        justify-content: space-between;
      }

      ion-item {
        --inner-padding-end: 0;
        --padding-start: 0;
      }

      ion-title {
        position: relative;
        color: black;
        font-size: 18px;
        font-weight: bold;
        padding: 0;
        background-color: white;

        &.left {
          text-align: left;
        }

        &.center {
          text-align: center;
        }

        &.right {
          text-align: right;
        }

        &.bolder {
          font-weight: 900;
        }

        &.lighter {
          font-weight: 400;
        }

        &.upper {
          text-transform: uppercase;
        }

        &.pad-left {
          padding-left: 5px;
        }

        &.pad-right {
          padding-right: 5px;
        }
      }
    }

    .buttons-container {
      display: flex;
      justify-content: space-around;
      // position: fixed;
      bottom: 0;
      padding: 8px 0;
      width: 100%;
      // border-top: 1px solid black;
      background-color: white;
    }
  }
}

ion-card {
  position: relative;
  background: white;
  border-radius: 20px;
  height: 130px;
  margin: 10px;
  display: flex;
  border: 1px solid white;
  box-shadow: none;

  &.pointer {
    cursor: pointer;
  }

  &.auto {
    min-height: 130px;
    height: auto;
  }

  &.small {
    height: 90px;
    align-items: center;
  }

  .img-container {
    width: 70px;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;

    img {
      height: 100%;
      width: auto;
      margin: 0;
      padding: 0;
      object-fit: cover;
    }

    .scrim {
      height: 100%;
      width: 100%;
      background: rgba(0, 0, 0, 0.42);
      position: absolute;
      top: 0;
      left: 0;
      display: flex;

      .icon {
        font-size: 30px;
        color: white;
        margin: auto;
      }
    }
  }

  .icon-container {
    width: 70px;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      font-size: 60px;
      color: white;

      &.small {
        font-size: 30px;
      }
    }
  }

  .values-container {
    width: 100%;
    padding: 5px;
    display: grid;

    .field {
      display: flex;
      margin: auto 10px;

      &.grid,
      .grid {
        display: grid;
        justify-items: start;
        align-items: center;
        align-content: center;
      }

      &.overflow,
      .overflow {
        overflow-y: auto;
        max-height: 80px;
        padding-top: 5px;
        margin-right: 0;
      }

      .icon {
        font-size: 18px;
        padding-right: 5px;
        color: black;
        margin: auto 0;
      }

      ion-card-title {
        @include boton1(black);
      }

      ion-title {
        padding: 0;
        position: relative;
        @include boton1(black);
        max-width: 80%;
      }

      ion-card-subtitle.value {
        @include cuerpoTexto1(black);
        // text-transform: capitalize;

        &.flex {
          display: flex;
          align-items: center;
        }

        &.bold {
          font-weight: bold;
        }

        &.italic {
          font-style: italic;
        }

        &.upper {
          text-transform: uppercase;
        }

        &.small {
          font-size: 12px;
        }
      }

      ion-card-subtitle.no-value {
        @include cuerpoTexto1(rgba(0, 0, 0, 0.42));
        font-style: italic;
      }
    }
  }

  .arrow-container {
    width: 50px;
    height: calc(100% - 20px);
    display: flex;
    justify-content: center;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    border-left: rgba(0, 0, 0, 0.05) solid 2px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    z-index: 0;

    i {
      color: $interactive;
      height: auto;
      margin: auto;
      font-size: 30px;
    }
  }

  ion-button {
    width: 50px;
    height: calc(100% - 20px);
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    --border-radius: 15px;
    --background: transparent;
    --box-shadow: none;
    --padding-start: 0;
    --padding-end: 0;

    i {
      height: auto;
      margin: auto;
      font-size: 30px;
      color: $interactive;
    }

    &.right {
      border-left: rgba(0, 0, 0, 0.05) solid 2px;
    }

    &.decrease {
      i {
        color: $cancel;
      }
    }
  }
}

ion-toggle {
  width: 70px;
  --background-checked: var(--emphasis);
}

ion-select,
ion-input,
ion-datetime {
  max-width: 100% !important;
  height: 28px;
  background: white;
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 4px;
  --padding-bottom: 4px;
  border: 1px solid black;
  border-radius: 25px;
  --border-radius: 25px;
  font-size: 14px;
  --placeholder-opacity: 0.5;

  &:read-only {
    input {
      cursor: default;
    }
  }

  &.transparent {
    border: 1px solid white;
    background: transparent;
    color: white;
  }
}

ion-textarea {
  max-width: 100% !important;
  background: white;
  margin: 0;
  --padding-start: 8px !important;
  --padding-end: 8px;
  --padding-top: 4px;
  --padding-bottom: 4px;
  border: 1px solid black;
  border-radius: 15px;
  font-size: 14px;
  --placeholder-opacity: 0.5;
}

span,
label {
  &.italic {
    font-style: italic;
  }

  &.bolder {
    font-weight: 900;
  }

  &.lighter {
    font-weight: 400;
  }

  &.bigger {
    font-size: 18px;
  }

  &.capitalize {
    text-transform: capitalize;
  }

  &.upper {
    text-transform: uppercase;
  }

  &.lower {
    text-transform: lowercase;
  }

  &.tick {
    color: $tick;
  }

  &.pro {
    color: $pro;
  }

  &.pass {
    color: $pass;
  }

  &.pal {
    color: $pal;
  }

  &.hub {
    color: $hub;
  }
  &.woutickplus {
    color: #cc3366;
  }
}

.item-container {
  width: 100%;
  padding: 10px 0;

  &.flex {
    display: flex;
    justify-content: space-between;

    .column {
      width: 48%;
    }
  }

  .input-btn-container {
    display: flex;
    align-items: center;

    ion-input {
      width: calc(100% - 34px - 16px);
    }
  }

  ion-label {
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 900;

    &.lbl-input {
      font-size: 16px;
      color: white;
      text-transform: uppercase;
      font-weight: 400;

      &.center {
        text-align: center;
        padding: 0;
        text-transform: none;
      }
    }
  }
}

.spinner {
  display: flex;
  height: 100%;

  ion-spinner {
    margin: auto;
  }
}

ion-list.content {
  padding: 8px 0;
  margin: 0 8px;

  &.no-margin {
    margin: 0;
  }

  &.no-pad {
    padding: 0;
  }

  &.line-top {
    border-top: 1px solid black;
  }

  &.line-bottom {
    border-bottom: 1px solid black;
  }

  ion-item.pad {
    --inner-padding-end: 8px;
    --padding-start: 8px;
  }
}

ion-list.list {
  width: 100%;
  background: transparent;
}

.list-segment {
  overflow: auto;
  height: calc(100% - 40px);
  z-index: -1;
}

ion-button.show-pass-button {
  margin: 0;
  margin-left: 16px;
  background: transparent;
  border: 1px solid white;
  border-radius: 20px;
  --border-radius: 20px;
  height: 34px;
  width: 34px;
  --padding-start: 0;
  --padding-end: 0;

  &.dark {
    border-color: black;

    .icon {
      color: black;
    }
  }

  .icon {
    font-size: 30px;
    color: white;
  }
}

ion-item {
  --background: transparent;
  --min-height: 0px !important;
  --border-color: black;

  &.line-top {
    .item-container {
      border-top: 1px solid black;
      padding-top: 20px;
    }
  }

  &.line-bottom {
    .item-container {
      border-bottom: 1px solid black;
      padding-bottom: 20px;
    }
  }

  ion-text {
    font-size: 14px;
    text-align: center;
  }
}

.line-container {
  height: 72px;
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0;

  &.light {
    background-color: white;

    label {
      background: white !important;
      border-right: 20px solid white !important;
      border-left: 20px solid white !important;
    }
  }

  .label-container {
    width: 100%;
    height: auto;
    position: absolute;
    text-align: center;

    label {
      @include principal5($fontColor);
      background: $backgroundColor;
      border-right: 20px solid $backgroundColor;
      border-left: 20px solid $backgroundColor;
    }
  }

  .line {
    display: block;
    height: 2px;
    background: $fontColor;
    width: 100%;
    margin: auto;
  }
}

.footer-background {
  display: none !important;
}

.home-description,
.tick-description,
.pro-description,
.pass-description,
.pal-description,
.hub-description,
.digital-description,
.contact-description,
.contact-container,
.woutickplus-description {
  color: white;
  height: 50%;
  padding: 0 5%;
  display: grid;
  align-items: center;
  align-content: center;

  .title {
    height: auto;
    padding: 0;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 4.5vw;
    line-height: 6vw;
  }

  .description {
    text-align: center;
    font-weight: 400;
    margin: 20px 0;
    font-size: 3vw;
    line-height: 4vw;
  }
}

.contact-container {
  height: 300px;

  &.tick {
    background: url("../../assets/img/contacts/tick.webp") no-repeat center
      center / cover;
  }

  &.pro {
    background: url("../../assets/img/contacts/pro.webp") no-repeat center
      center / cover;
  }

  &.pass {
    background: url("../../assets/img/contacts/pass.webp") no-repeat center
      center / cover;
  }

  &.pal,
  &.digital {
    background: url("../../assets/img/contacts/pal.webp") no-repeat center
      center / cover;
  }

  &.hub {
    background: url("../../assets/img/contacts/hub.webp") no-repeat center
      center / cover;
  }
}

.background-content-info {
  &.white {
    background-color: white;
  }

  .content-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1000px;
    height: 350px;
    margin: auto;
    padding: 30px 0;

    &.light {
      background-color: white;
    }

    img:not(.illustration) {
      height: 150px;
      position: absolute;
      z-index: -1;
      opacity: 0.9;

      &.right {
        right: 0;
      }

      &.left {
        left: 0;
      }
    }

    .info {
      width: 70%;
      margin: auto;

      &.right {
        margin-right: 20px;

        .title,
        .content {
          text-align: right;
        }
      }

      &.left {
        margin-left: 20px;

        .title,
        .content {
          text-align: left;
        }
      }

      &.center {
        margin: auto;
        width: 90%;

        .title,
        .content {
          text-align: center;
        }
      }

      .title {
        height: auto;
        padding: 20px 0;
        position: relative;
        text-transform: uppercase;
        font-weight: 900;
        font-size: 4.5vw;
        line-height: 6vw;
      }

      .content {
        font-weight: 400;
        font-size: 2.5vw;
        line-height: 3.5vw;
        width: 100% !important;
      }

      .content-button {
        padding: 20px 0;
        max-width: 450px;
        margin: auto;
      }
    }
  }
}

div.legal {
  background-color: $primaryColor;

  div.content {
    padding: 20px 10%;
    width: 100%;
    margin: auto;
  }

  .title {
    font-size: 34px;
    font-weight: 900;
    text-transform: uppercase;
  }

  .subtitle {
    font-size: 16px;
    font-weight: 900;

    &.warning {
      font-size: 12px;
      color: $tick;
      text-transform: uppercase;
      padding: 0 20px;
    }
  }

  .itemtitle {
    font-size: 14px;
    font-weight: 900;
  }

  .text {
    font-size: 14px;
    font-weight: 400;

    &.warning {
      font-size: 12px;
      color: $tick;
      padding: 0 20px;
    }
  }

  span {
    &.bold {
      font-weight: 900;
    }
  }
}

@media screen and (max-width: 420px) {
  .popover-content {
    left: 0 !important;
    width: 100% !important;
  }
}

@media screen and (min-width: 600px) {
  .content {
    margin: 0 auto !important;
    width: 460px;
  }

  .home-description,
  .tick-description,
  .pro-description,
  .pass-description,
  .pal-description,
  .hub-description,
  .digital-description,
  .contact-description,
  .contact-container,
  .woutickplus-description {
    padding-left: 10% !important;
    padding-right: 10% !important;

    .title {
      font-size: 28px !important;
      line-height: 30px !important;
    }

    .description {
      font-size: 16px !important;
      line-height: 20px !important;
    }
  }

  .background-content-info {
    .content-info {
      width: 80% !important;

      img.left,
      img.right,
      img.center {
        height: 250px !important;
        position: relative;
        opacity: 1 !important;
      }

      .info {
        width: 100% !important;

        &.right {
          margin-right: 0 !important;
        }

        &.left {
          margin-left: 0 !important;
        }

        .title {
          font-size: 24px !important;
          line-height: 26px !important;
          padding: 10px 0 !important;
        }

        .content {
          font-size: 14px !important;
          line-height: 18px !important;
        }
      }
    }
  }
}

@media screen and (min-width: 735px) {
  div.legal {
    .content {
      width: 90% !important;
    }
  }
}

@media screen and (min-width: 1028px) {
  ion-header.menu {
    ion-title {
      text-align: left !important;
      font-weight: 900 !important;
    }

    .search-button {
      display: flex !important;
    }
  }

  button.fab-button.search {
    display: none !important;
  }
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
